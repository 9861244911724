<template>
  <div class="m-4" :style="pageStyle">
    <div class="mb-4 topbar  d-print-none">
      <div>
        <h2><b-link :to="{ name: 'print' }">Print Shop</b-link></h2>
        <h2 class="m-2">&raquo;</h2>
        <h2>{{ printSetInfo.title }}</h2>
      </div>
    </div>

    <b-alert variant="warning" :show="!printSet.length && !loading" class="m-5">
      You have no title strips marked to print in this print set. <ul>
        <li><b-link :to="{ name: 'add' }">Add a record</b-link> to add a new title strip to a print set.</li>
        <li>Go to <b-link :to="{ name: 'collection' }">My Collection</b-link> to add existing title strips to a print
          set.
        </li>
        <li>Go to <b-link :to="{ name: 'designer' }">Layout Studio</b-link> to add an entire layout to a print set.</li>
      </ul>
    </b-alert>
    <div v-if="printSet.length" class="position-relative pageSet">
      <div class="position-absolute instructions">
        <h3>Title Strip Count</h3>
        <ul class="mb-4">
          <li v-if="records45.length">{{ records45.length * printSetInfo.printCount }} x45s</li>
          <li v-if="records78.length">{{ records78.length }} x78s</li>
        </ul>
        <h3>Print Tips</h3>
        <ul>
          <li>Print this page</li>
          <li>Set "Save as PDF" as your printer</li>
          <li>Print Actual size. Not scale to fit</li>
          <li>Print on {{ getPageSize(printSetInfo.page).text }} 70lb card stock</li>
          <li>Title strips should measure 3" x 1"</li>
        </ul>
      </div>
      <Grid45 :records="records45" :printSetInfo="printSetInfo" :repeat="printSetInfo.printCount"
        @remove="removeRecordFromPrintSet" v-if="records45" />
      <Grid78 :records="records78" v-if="records78" />
    </div>
  </div>
</template>

<script>
import Grid45 from "@/components/45/Grid";
import Grid78 from "@/components/78/Grid";

import { SPEED_45, SPEED_78 } from "@/consts";
import { GET_PRINTSET } from "@/queries";
import { PAGE_SIZES } from "@/consts";

import makeRequest from "@/requests/makeRequest";

export default {
  name: "print",
  data() {
    return {
      PAGE_SIZES,
      loading: true,
      printSet: [],
    };
  },
  components: {
    Grid45,
    Grid78,
  },
  computed: {
    records45() {
      return this.printSet.filter((record) => record.speed === SPEED_45) || null;
    },
    records78() {
      return this.printSet.filter((record) => record.speed === SPEED_78) || null;
    },
    printSetInfo() {
      return this.$store.getters.getPrints.filter((print) => print._id === this.$route.params.id)[0];
    },
    pageStyle() {
      const selectedSize = this.getPageSize(this.printSetInfo.page)
      // Compute and return the CSS styles based on the selected page size
      if (selectedSize) {
        return {
          width: selectedSize.width, // Set the width based on the selected size
          height: selectedSize.height, // Set the height based on the selected size
          // Add other styles as needed
          '@media print': {
            '@page': {
              size: `${selectedSize.width} ${selectedSize.height}`,
            },
          },
        };
      }
      // Default styles if no size is selected
      return {
        width: "100%",
        height: "100%",
      };
    }
  },
  methods: {
    getPageSize(page) {
      return PAGE_SIZES.filter((size) => size.value === page)[0];
    },
    removeRecordFromPrintSet(id) {
      this.printSet = this.printSet.filter((record) => record.printSetId !== id);
    },
    setPageSize(width, height) {
      const style = document.getElementById('printPage');
      style.textContent = `@media print {
    @page {
      size: ${width}in ${height}in;
    }
  }`;
    },
  },
  mounted() {
    makeRequest(GET_PRINTSET, { id: this.$route.params.id }).then((res) => {
      if (res.data.data) {
        this.printSet = res.data.data.printData.map((item) => ({
          printSetId: item._id,
          titleStrip: item.titleStrip,
          ...item.record_id,
        }));
        const pageSize = this.getPageSize(this.printSetInfo.page)
        this.setPageSize(pageSize.width, pageSize.height)
      } else {
        this.printSet = []
      }
      this.loading = false
    });
  },
};
</script>
<style scoped>
.instructions {
  position: absolute;
  top: 120px;
  left: 645px;
}

ul {
  margin-left: 20px;
  padding: 0;
}

h2 {
  display: inline;
}

.pageSet {
  min-height: 400px;
}

@media print {
  .m-4 {
    margin: 0rem !important;
  }
}

.topbar {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
